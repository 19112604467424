import React from 'react';
import Maps from './components/Maps';
import { Container, Row, Col } from 'react-bootstrap';
import UserForm from './components/UserForm';
import styled from 'styled-components';

const Styles = styled.div`
h2{
  font-family: 'Dancing Script', cursive;
  padding: 5px 5px;
  text-align: center;
  width: 100%;
}
`;

export const Contact = () => (
  <Styles>
     <Container fluid ="md">
      <Row>
        <Col sm={6}>
        <h2>CONTACT ME</h2>
          <UserForm />
        </Col>
        <Col sm={6}>
          <Maps />
        </Col>
      </Row>
     </Container>
  </Styles>
)
