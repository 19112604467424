import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import homeVisit from './assets/homeVisit.jpg';
import luxCare from './assets/luxCare.jpg';
import nailSurgery from './assets/nailSurgery.jpg';
import verucca from './assets/verucca.jpg';
import ingrownNail from './assets/ingrownNail.jpg';
import footCare from './assets/footCare.jpg';
import bioMechAssessment from './assets/bioMechAssessment.jpg';
import routineCare from './assets/routineCare.jpg';
import routinePodiatryTreatment from './assets/routinePodiatryTreatment.jpg';
import luxPlusTreatment from './assets/luxPlusTreatment.jpg';


const Styles = styled.div`

.PageTitle{
  text-align: center;
}

h1, h2, h5{
  font-family: 'Dancing Script', cursive;
  padding: 5px 5px;
  text-align: center;
  width: 100%;
}

.Card{
  box-shadow: 15px 10px 5px #888888;
  min-height: 200px;
  margin: 50px auto;
  background: white;
  border-radius: 5px;
  border: 1px solid grey;
}

.ServicesContainer{
  display: block;
  width: 100%;
  margin: 0px 0px;
  padding: 10px 10px;
}

.ServiceImage{
  display: flex;
  margin: auto;
  width: 224px;
  height: 224px;
}

.ServicesText{
  padding: 5px 5px;
  text-align: center;
}

.astrodivider {
  margin:64px auto;
  width:400px;
  max-width: 100%;
  position:relative;

  i {
    position:absolute;
    top:4px; bottom:4px;
    left:4px; right:4px;
    border-radius:100%;
    border:1px dashed #68beaa;
    text-align:center;
    line-height:40px;
    font-style:normal;
     color:#049372;
   }

  span {
    width:50px; height:50px;
    position:absolute;
    bottom:100%; margin-bottom:-25px;
    left:50%; margin-left:-25px;
    border-radius:100%;
    box-shadow:0 2px 4px #4fb39c;
    background:#fff;
    }
}

.astrodividermask {
  overflow:hidden; height:20px;

  &:after{
    content:'';
    display:block; margin:-25px auto 0;
    width:100%; height:25px;
    border-radius:125px / 12px;
    box-shadow:0 0 8px #049372;
  }
}
`;


export const Services = () => (
  <Styles>
    <h1 className="PageTitle">Podiatry Services Home & Clinic</h1>
    <p className="PageTitle">
      Thank you for taking the time to browse the services on offer, please do not hesitate to contact me if there are any queries or concerns you may have regarding these services or if you would like to make a booking!
    </p>

    <Container fluid="md" className="ServicesContainer">
      <Row>
        <Col lg="6" className="d-none d-lg-block">
          <img src={luxPlusTreatment} className="ServiceImage" width="150" height="150" alt="ServiceImage" />
        </Col>
        <Col className="Card">
          <Row>
            <h2>Luxury Plus Treatment</h2>
          </Row>
          <Row>
            <h5>1 Hour 30 Minutes - £50</h5>
          </Row>
          <Row>
            <p className="ServicesText">
              The ultimate experience for anyone who wants to leave their stress behind. Unwind and enjoy a full podiatry treatment followed by a foot scrub, soak and then a wonderful warm wax therapy treatment to increase blood flow, relax the muscles and increase hydration to the skin.<br />
            </p>
          </Row>
        </Col>
      </Row>
    </Container>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <Container fluid="md" className="ServicesContainer">
      <Row>
        <Col className="Card">
          <Row>
            <h2>Luxury Medical Pedicure</h2>
          </Row>
          <Row>
            <h5>1 Hour 15 Minutes and over - £40</h5>
          </Row>
          <Row>
            <p className="ServicesText">
              Relax and soothe the stresses of life away with this luxury 1 hour treatment. Relax while you enjoy a full podiatry treatment, scrub, soak and foot mask. Finish off with a wonderful massage..... utter Bliss!! <br />
              <strong><sup>* Clinic Only</sup></strong><br />
              <strong><sup>* Subject to clinicians discretion if patient has never been seen before</sup></strong>
            </p>
          </Row>
        </Col>
        <Col lg="6" className="d-none d-lg-block">
          <img src={luxCare} className="ServiceImage" width="150" height="150" alt="ServiceImage" />
        </Col>
      </Row>

    </Container>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <Container fluid="md" className="ServicesContainer">
      <Row>
        <Col lg="6" className="d-none d-lg-block">
          <img src={verucca} className="ServiceImage" width="150" height="150" alt="ServiceImage" />
        </Col>
        <Col className="Card">
          <Row>
            <h2>Verucca Treatment</h2>
          </Row>
          <Row>
            <h5>30 Minutes - £20-£30</h5>
          </Row>
          <Row>
            <p className="ServicesText">
              Verruca treatment and advice using a variety of caustics and home remedies <br />
              <strong><sup>* Clinic Only</sup></strong>
            </p>
          </Row>
        </Col>
      </Row>
    </Container>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <Container fluid="md" className="ServicesContainer">
      <Row>
        <Col className="Card">
          <Row>
            <h2>Biomechanical Assessment</h2>
          </Row>
          <Row>
            <h5>1 Hour and over - £35 To £85</h5>
          </Row>
          <Row>
            <p className="ServicesText">
              Full assessment of the lower limb including gait analysis and provision of orthotic therapy if required <br />
              <strong><sup>* Clinic Only</sup></strong>
            </p>
          </Row>
        </Col>
        <Col lg="6" className="d-none d-lg-block" margin="auto">
          <img src={bioMechAssessment} className="ServiceImage" alt="ServiceImage" />
        </Col>
      </Row>
    </Container>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <Container fluid="md" className="ServicesContainer">
      <Row>
        <Col lg="6" className="d-none d-lg-block">
          <img src={routineCare} className="ServiceImage" width="150" height="150" alt="ServiceImage" />
        </Col>
        <Col className="Card">
          <Row>
            <h2>Routine Podiatry Care</h2>
          </Row>
          <Row>
            <h5>30 Minutes - £34</h5>
          </Row>
          <Row>
            <p className="ServicesText">
              Regular podiatry treatment in 6 to 8 weekly intervals<br />
            </p>
          </Row>
        </Col>
      </Row>
    </Container>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <Container fluid="md" className="MainServicesContainer">
      <Col>
        <Container fluid="md" className="ServicesContainer">
          <Row>
            <Col className="Card">
              <Row>
                <h2>Home Visit</h2>
              </Row>
              <Row>
                <h5>45 Minutes - £35</h5>
              </Row>
              <Row>
                <p className="ServicesText">
                  Full Podiatry assessment and treatment in the comfort of your home. Usually reserved for people who have mobility issues, or for those who are unable to attend the clinic
                </p>
              </Row>
            </Col>
            <Col lg="6" className="d-none d-lg-block" margin="auto">
              <img src={homeVisit} className="ServiceImage" alt="ServiceImage" />
            </Col>
          </Row>
        </Container>

        <div className="astrodivider">
          <div className="astrodividermask">
          </div>
          <span>
            <i>
              &#10038;
            </i>
          </span>
        </div>

        <Container fluid="md" className="ServicesContainer">
          <Row>
            <Col lg="6" className="d-none d-lg-block" margin="auto">
              <img src={nailSurgery} className="ServiceImage" alt="ServiceImage" />
            </Col>
            <Col className="Card">
              <Row>
                <h2>Nail Surgery</h2>
              </Row>
              <Row>
                <h5>2 Hours and over - £220 To £300</h5>
              </Row>
              <Row>
                <p className="ServicesText">
                  Removal of part or all of the toenail if there has been no improvement with conservative treatments. All dressings and follow up appointments are included<br />
                  <strong><sup>* Clinic only</sup></strong>
                </p>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="astrodivider">
          <div className="astrodividermask">
          </div>
          <span>
            <i>
              &#10038;
            </i>
          </span>
        </div>

        <Container fluid="md" className="ServicesContainer">
          <Row>
            <Col className="Card">
              <Row>
                <h2>Ingrown Toenail Removal</h2>
              </Row>
              <Row>
                <h5>30 Minutes - £34</h5>
              </Row>
              <Row>
                <p className="ServicesText">
                  Removal of painful onycocryptosis. Full dressings included
                </p>
              </Row>
            </Col>
            <Col lg="6" className="d-none d-lg-block" margin="auto">
              <img src={ingrownNail} className="ServiceImage" alt="ServiceImage" />
            </Col>
          </Row>
        </Container>

        <div className="astrodivider">
          <div className="astrodividermask">
          </div>
          <span>
            <i>
              &#10038;
            </i>
          </span>
        </div>

        <Container fluid="md" className="ServicesContainer">
          <Row>
            <Col lg="6" className="d-none d-lg-block">
              <img src={footCare} className="ServiceImage" width="150" height="150" alt="ServiceImage" />
            </Col>
            <Col className="Card">
              <Row>
                <h2>Nail Reconstruction</h2>
              </Row>
              {/* <Row>
                <h5>1 Hour 45 Minutes and over - £30</h5>
              </Row> */}
              <Row>
                <p className="ServicesText">
                  Reconstruction of entire toenail if it has been damaged o removed surgically using LCN Wilde Pedique. Can also be used to cover unsightly discolouration of nail<br />
                </p>
              </Row>
            </Col>
          </Row>
        </Container>

      </Col>
    </Container>
  </Styles>
)
