import React from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';
import facebook_logo from '../assets/facebook_logo.png';
import instagram_logo from '../assets/instagram_logo.png';

const Styles = styled.div`
.main-footer{
  color: white;
  background-color: #e0edb5;
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.container {
  padding: 0px 1px;
  margins: auto;
  display: flex;
  align-items: flex-start;
}

.row {
  justify-content: left;
  margin: 10px 0px 0px 10px;
  padding:10px 0px 0px 0px;
}

.col1, .col2 {
  padding: 0px 12px 0px 0px;
  font-size: 15px;
  color: black;
}

.col3 {
}

.ul {
  padding: 0px;
}

.li {
  color: black;
  &:hover {
   color: white;
  }
}

.social_image {
  width: 60px;
  height: 50px;
  padding: 0px 5px 10px 10px;
  justify-content: space-between;
}

h6 {
  font-family: 'Dancing Script', cursive;
  font-size: 25px;
}

`;

export const Footer = () => (
  <Styles>
    <div className = "main-footer">
      <div className = "container">
        <div className = "row" >
          <div className = "col1">
            <h6>Contact</h6>
              <ul className = "list-unstyled">
                <li>101 Abbeygreen</li>
                <li>Lesmahagow</li>
                <li>ML11 0EF</li>
                <li>TEL: 07936 774822</li>
                <li>Email: alisonglen@alisonmeekpodiatry.co.uk</li>
              </ul>
          </div>
          <div className = "col2">
            <h6>Navigation</h6>
            <ul className = "list-unstyled">
                <li><Nav.Item><Nav.Link href="/">Home ></Nav.Link></Nav.Item></li>
                <li><Nav.Item><Nav.Link href="/services">Services ></Nav.Link></Nav.Item></li>
                <li><Nav.Item><Nav.Link href="/about">About ></Nav.Link></Nav.Item></li>
                <li><Nav.Item><Nav.Link href="/contact">Contact ></Nav.Link></Nav.Item></li>
            </ul>
          </div>
          <div className = "col3">
              <a href="https://www.facebook.com/AlisonMeekPodiatry/"><img className="social_image" src={facebook_logo} alt="Facebook logo" /></a>
              <a href="https://www.instagram.com/alison.glen/"><img className="social_image" src={instagram_logo} alt="Instagram logo" /></a>
          </div>
        </div>
      </div>
    </div>
  </Styles>
)
