import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import styled from 'styled-components';

const Styles = styled.div`
.leaflet-container{
  width: 100%;
  height: 50vh;
  border: solid 1px black;
}

 .address {
    display: inline-block;
    font-size: 80%;
    line-height: normal;
    width: 30%;
    line-height: 2rem;
    border: 1px solid #019ed5;
    border-radius: 25px;
    cursor: pointer;
 }
`;

function Maps() {
  return(
    <Styles>
    <a href="https://goo.gl/maps/k41FRitSqdm5ZTSF8">Get Google Map Directions</a><br/>
      <Map center={[55.6366883, -3.8890677]}
        zoom={15}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'>
          </TileLayer>
      <Marker
        position={[55.6366883, -3.8890677]}>
          <Popup>
            <span id="address">Alison Meek Podiatry Office<br />
              101 Abbeygreen<br />
              Lesmahagow<br />
              ML11 0EF
            </span>
          </Popup>
        </Marker>
      </Map>
      </Styles>
  );
}

export default Maps;
