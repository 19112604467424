import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { MdPermIdentity } from "react-icons/md";
import { FcPhone, FcAddressBook, FcServices  } from "react-icons/fc";
import emailjs from 'emailjs-com';
import styled from 'styled-components';

const Styles = styled.div`
.contactForm {
  display: flex;
  width: 100%;
  height: 50vh;
  overflow: auto;
  justify-content: center;
}

.submitButton {
  margin: 0 auto;
  width: 90px;
  height: 40px;
  border-radius: 10px 10px 10px 10px;
}

label {
  display: flex;
  width: 75%;
  padding: 5px 5px 5px 5px;
}

input {
  width: 100%;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #DDDDDD;

  &:focus {
    box-shadow: 0 0 5px #51cbee;
    border: 1px solid #51cbee;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
  }
}

`;

class UserForm extends Component{
  constructor(props){
    super(props);
      this.state = {
        customerName: '',
        customerNumber: '',
        customerEmail: '',
        serviceRequested: ''
      };

      this.handleNameChange = this.handleNameChange.bind(this);
      this.handleNumberChange = this.handleNumberChange.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleserviceRequestedChange = this.handleserviceRequestedChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleNameChange(event){
      this.setState({customerName: event.target.value});
    }

    handleNumberChange(event){
      this.setState({customerNumber: event.target.value});
    }

    handleEmailChange(event){
      this.setState({customerEmail: event.target.value});
    }

    handleserviceRequestedChange(event){
      this.setState({serviceRequested: event.target.value});
    }

    handleSubmit(e){
      e.preventDefault();
      emailjs.send('Contact_Service', 'template_cp93vz8',this.state, 'user_HrfhkVSFkiW6OsQFiCfHt')
      .then((result) => {
        console.log(result.text);
        this.setState({
          customerName: '',
          customerNumber: '',
          customerEmail: '',
          serviceRequested: ''
        });
        alert("Your Booking Request Has Been Sent!")
      },(error) => {
        console.log(error.text);
        alert("Oops, something went wrong and the booking request was not sent!")
      });
    };

  render(){
    return(
  <Styles>
    <Form className="contactForm" onSubmit={this.handleSubmit}>
      <Col>
        <Row>
          <label>
            <MdPermIdentity size={30}/>
            <input type="text" id="customerName" value={this.state.customerName} placeholder="Full name" onChange={this.handleNameChange} required />
          </label>
        </Row>
        <Row>
          <label>
            <FcPhone size={30} />
            <input type="text" id="customerNumber" value={this.state.customerNumber} placeholder="Phone Number" onChange={this.handleNumberChange} required />
          </label>
        </Row>
        <Row>
          <label>
            <FcAddressBook size={30} />
            <input type="email" id="customerEmail" value={this.state.customerEmail} placeholder="Email address" onChange={this.handleEmailChange} required />
          </label>
        </Row>

        <Row>
          <label>
            <FcServices size={30} />
            <select value={this.state.serviceRequested} id="serviceRequested" defaultValue="Select Service"onChange={this.handleserviceRequestedChange} required>
              <option value="Select Service">Select Service</option>
              <option value="Home Visit">Home Visit</option>
              <option value="Luxury Medical Pedicure">Luxury Medical Pedicure</option>
              <option value="Nail Surgery">Nail Surgery</option>
              <option value="Verucca Treatment">Verucca Treatment</option>
              <option value="Ingrown Toenail Removal">Ingrown Toenail Removal</option>
              <option value="Footcare Advice">Footcare Advice</option>
              <option value="Biomechanical Assessment">Biomechanical Assessment</option>
              <option value="Routine Podiatry Care">Routine Podiatry Care</option>
            </select>
          </label>
          </Row>
          <Button className="submitButton" type="submit" value="Submit">Submit</Button>
      </Col>
    </Form>
  </Styles>
  )}
}

export default UserForm;
