import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
 .navbar {
   background-color: #e0edb5;
   width: 100%;
 }

 h4, .navbar-nav .nav-link {
   color: black;
   font-family: 'Dancing Script', cursive;
   font-size: 30px;

   &:hover {
     color: white;
   }
 }
`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg">
    <h4>Alison Meek Podiatry</h4>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/services">Services</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/about">About</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/contact">Contact</Nav.Link></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
)
