import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { Services } from './Services';
import { About } from './About';
import { Contact } from './Contact';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';
import { Jumbotron } from './components/Jumbotron';

class App extends Component{
  render(){
    return (
      <React.Fragment>
      <NavigationBar />
      <Jumbotron />
      <Layout>
          <Router>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/services" component={Services}/>
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </Layout>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
