import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import feetImage from '../assets/feetImage.jpg';

const Styles = styled.div`
  .jumbo {
    background: url(${feetImage}) no-repeat fixed bottom;
    background-size: cover;
    color: #efefef;
    height: 250px;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: -2;
  }

  .overlay {
    background-color: #000;
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

`;

export const Jumbotron = () => (
    <Styles>
      <Jumbo fluid className="jumbo">
        <div className="overlay"></div>
        <Container>
        </Container>
      </Jumbo>
    </Styles>
)
