import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import entrance from './assets/entrance.jpg';
import hall from './assets/hall.jpg';
import podiatristDesk from './assets/podiatristDesk.jpg';
import reception from './assets/reception.jpg';
import refreshmentUnit from './assets/refreshmentUnit.jpg';
import therapySeat from './assets/therapySeat.jpg';
import therapyTable from './assets/therapyTable.jpg';
import treatmentMenu from './assets/treatmentMenu.jpg';
import treatmentRoom from './assets/treatmentRoom.jpg';
import waitingArea from './assets/waitingArea.jpg';
import wallDecor from './assets/wallDecor.jpg';
import wallDecor2 from './assets/wallDecor2.jpg';
import wallDecor3 from './assets/wallDecor3.jpg';
import buddha from './assets/buddha.jpg';
import clinicOutside from './assets/clinicOutside.jpg';
import certificates from './assets/certificates.jpg';
import creams from './assets/creams.jpg';
import products from './assets/products.jpg';
import products2 from './assets/products2.jpg';
import products3 from './assets/products3.jpg';
import products4 from './assets/products4.jpg';
import products5 from './assets/products5.jpg';
import products6 from './assets/products6.jpg';
import styled from 'styled-components';


const Styles = styled.div`
.title {
  text-align: center;
}

.titleIntroduction {
  text-align: center;
}

h2, h3{
  font-family: sans-serif, cursive;
  padding: 5px 5px;
  text-align: center;
  width: 100%;
}

.astrodivider {
  margin:64px auto;
  width:400px;
  max-width: 100%;
  position:relative;

  i {
    position:absolute;
    top:4px; bottom:4px;
    left:4px; right:4px;
    border-radius:100%;
    border:1px dashed #68beaa;
    text-align:center;
    line-height:40px;
    font-style:normal;
     color:#049372;
   }

  span {
    width:50px; height:50px;
    position:absolute;
    bottom:100%; margin-bottom:-25px;
    left:50%; margin-left:-25px;
    border-radius:100%;
    box-shadow:0 2px 4px #4fb39c;
    background:#fff;
    }
}

.astrodividermask {
  overflow:hidden; height:20px;

  &:after{
    content:'';
    display:block; margin:-25px auto 0;
    width:100%; height:25px;
    border-radius:125px / 12px;
    box-shadow:0 0 8px #049372;
  }
}

.sliderimg{
  width: 100%;
  height: 500px;
  object-fit: contain;
}
`;

export const Home = () => (

  <Styles>
    <h2 className="title">Alison Meek Podiatry</h2>
    <p className="titleIntroduction">Welcome to Alison Meek Podiatry. Offering over 18 years of specialist podiatry experience to treat a wide spectrum of feet conditions. My clinic is run from Lesmahagow, as well as offering home visits on a variety of services. I am registered with the Health and Care Professions Council (HCPC) amongst others.</p>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <AliceCarousel autoPlay autoPlayInterval={5000}>
      <img src={entrance} className="sliderimg" alt="entrance" />
      <img src={reception} className="sliderimg" alt="reception" />
      <img src={waitingArea} className="sliderimg" alt="waitingArea" />
      <img src={hall} className="sliderimg" alt="hall" />
      <img src={treatmentMenu} className="sliderimg" alt="treatmentMenu" />
      <img src={treatmentRoom} className="sliderimg" alt="treatmentRoom" />
      <img src={therapySeat} className="sliderimg" alt="therapySeat" />
      <img src={therapyTable} className="sliderimg" alt="therapyTable" />
      <img src={podiatristDesk} className="sliderimg" alt="podiatristDesk" />
      <img src={wallDecor} className="sliderimg" alt="wallDecor" />
      <img src={wallDecor2} className="sliderimg" alt="wallDecor2" />
      <img src={wallDecor3} className="sliderimg" alt="v" />
      <img src={refreshmentUnit} className="sliderimg" alt="refreshmentUnit" />
      <img src={buddha} className="sliderimg" alt="buddha" />
      <img src={creams} className="sliderimg" alt="v" />
      <img src={products} className="sliderimg" alt="products" />
      <img src={products2} className="sliderimg" alt="products2" />
      <img src={products3} className="sliderimg" alt="products3" />
      <img src={products4} className="sliderimg" alt="products4" />
      <img src={products5} className="sliderimg" alt="products5" />
      <img src={products6} className="sliderimg" alt="products6" />
      <img src={certificates} className="sliderimg" alt="certificates" />
      <img src={clinicOutside} className="sliderimg" alt="clinicOutside" />
    </AliceCarousel>

    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <h3>What Is Podiatry?</h3>
    <p>
      Podiatry or Podiatric Medicine  is a branch of medicine devoted to the study, diagnosis and treatment of disorders of the foot, ankle and lower extremity. The term Podiatry was introduced in the early 20th century where previously it was more commonly known as Chiropody.
      <br />
      <br />
      Podiatrists are healthcare professionals who have been trained to diagnose and treat abnormal conditions of the feet and lower limb. They also prevent and correct deformity, keep people mobile and active, relieve pain and treat infections. The qualities required to undertake Podiatry are; critical thinking, analytical skills, professionalism, management skills, communication and interpersonal skills. Podiatric specialities include; sports medicine, high risk wound care, rheumatology, neurology, skin cancers, vascular assessment, dermatology, diabetic limb salvage and wound care, podopaediatrics and forensic podiatry.
    </p>
    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>

    <h3>How Can A Podiatrist Help Me?</h3>
    <p>
      A Podiatrist can diagnose your feet, and suitably tailor a treatment plan to ensure that your feet are suitably looked after and you are given the knowledge to self care at home.  Podiatrists have an array of tools at their disposal including but not limited to pharmaceuticals, massage therapy, physical therapy, minor surgery and specialist shoes and orthotics to help support your feet whilst walking. We can provide rehabilitation to help keep your feet mobile and you active. Podiatrist also carry out routine treatments to prevent conditions deteriorating, and to maintain healthy feet.
      <br />
      <br />
      Podiatrists can help with a very large spectrum of foot and ankle issues, including:
    </p>
    <ul>
      <li>Toenail problems</li>
      <li>Corns and Callouses</li>
      <li>Verucca</li>
      <li>Athletes Foot</li>
      <li>Dry and Cracked Heels</li>
      <li>Flat feet</li>
      <li>Bunions</li>
      <li>Ageing Feet</li>
      <li>Blisters</li>
      <li>Gout</li>
      <li>Sports Injuries</li>
      <li>Ulcers</li>
      <li>Diabetic Assessments</li>
      <li>Biomechanics</li>
      <li>Orthotic prescribing</li>
    </ul>


    <div className="astrodivider">
      <div className="astrodividermask">
      </div>
      <span>
        <i>
          &#10038;
        </i>
      </span>
    </div>
  </Styles>
)
