import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import profilePhoto from './assets/Alison_Profile.jpg';
import styled from 'styled-components';

const Styles = styled.div`

.PageTitle {
  text-align: center;
}

h1, h2, h4, h5 {
  font-family: 'Dancing Script', cursive;
  padding: 5px 5px;
  text-align: center;
  width: 100%;
}

.profileImg {
  border-radius: 25px;
  display: flex;
  margin: auto;
  width: 50%;
  height: auto;
}

.subhead::first-letter {
  float: left;
  font-family: Georgia, serif;
  font-size: 75px;
  line-height: 60px;
  padding: 3px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.affiliations {
  display: flex;
  font-family: Hack, monospace;
  background: #e0edb5;
  color: #1d1d1d;
  cursor: pointer;
  font-size: 20px;
  padding: 5px 5px;
  margin: 15px 15px;
  transition: all 0.5s;
  border-radius: 10px;
  width: 75%;
  position: relative;
  min-width: 150px;
  justify-content: center;
  text-align: center;
  margin: 10px auto;

  &::after {
      font-weight: 400;
      position: absolute;
      left: 80%;
      top: 54%;
      right: 0;
      bottom: 0;
      opacity: 0;
      transform: translate(-50%, -50%);
  }

  &:hover {
    background: #32ff7e;
    border: 3px solid #22c0e8;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.5s;
    border-radius: 10px;
    box-shadow: 0px 6px 10px #0000ff61;
    padding: 1.5rem 3rem 1.5rem 1.5rem;

    &::after {
        opacity: 1;
        transition: all 0.5s;
        color: #ffffff;
    }
  }
}

.astrodivider {
  margin:64px auto;
  width:400px;
  max-width: 100%;
  position:relative;

  i {
    position:absolute;
    top:4px; bottom:4px;
    left:4px; right:4px;
    border-radius:100%;
    border:1px dashed #68beaa;
    text-align:center;
    line-height:40px;
    font-style:normal;
     color:#049372;
   }

  span {
    width:50px; height:50px;
    position:absolute;
    bottom:100%; margin-bottom:-25px;
    left:50%; margin-left:-25px;
    border-radius:100%;
    box-shadow:0 2px 4px #4fb39c;
    background:#fff;
    }
}

.astrodividermask {
  overflow:hidden; height:20px;

  &:after{
    content:'';
    display:block; margin:-25px auto 0;
    width:100%; height:25px;
    border-radius:125px / 12px;
    box-shadow:0 0 8px #049372;
  }
}
`;

export const About = () => (
  <Styles>
    <h2 className="PageTitle">A Little About Me</h2>
    <Container fluid ="md">
      <Row>
        <Col sm={6}>
          <img className="profileImg"src = { profilePhoto } alt="AlisonProfilePhoto"/>
        </Col>
        <Col sm={6}>
          <p className="subhead">
            Most people I treat ask me "What made you become a Podiatrist?" And always my answer is "I don't know". Its not a job for the faint hearted. It isn't glamorous. It doesn't pay very well and you never ever manage to treat that very famous footballer that you were told you would treat at University. But you know what, I <strong>LOVE</strong> my job. I have the best job in the world. I meet some wonderful people. I treat them for many years, I hear their family stories, I hear their good news, bad news. I see them when they have had a bad day, or a good day. We spend a lot of time together.
          <br />
          <br />
            I don't think there is any job in the world where you could become so close to a patient, you feel a little like family. And that is what I like. I love that someone can come to see me in real pain, and within 30 minutes or so, they walk out almost pain free. It is so very satisfying. My aim within my clinic is to make you feel at ease and at home. I am proud to be a Podiatrist, carrying on the tradition of looking after your feet. which in turn increases your personal health and wellbeing. I am knowledgeable and friendly, bringing over 18 years experience in Podiatric Medicine. I ensure a high standard of competent care for a wide variety of patients with diverse needs. In the past I have worked with people of all ages who have complex needs both physical and mental such as Autism, stroke survivors, Dementia, Parkinsons and Downs syndrome.
          <br />
          <br />
            I understand that building rapport is very important and I take the time to do this especially with children and patients with additional needs. I have a keen interest in working with children and dermatology. I currently have a clinic in Lesmahagow where I work several days a week and also provide home visits for patients who cannot attend clinic. I also visit care homes and hospitals and work closely with Scottish Autism. Whatever your foot care need is, just give me a call, text, email. My phone is always on. I will try my very best to help you and get you back on your feet hopefully pain free.
          <br />
          <br />
            Healthy feet are happy feet. See you soon!
            </p>
        </Col>
      </Row>

      <div className="astrodivider">
        <div className="astrodividermask">
        </div>
          <span>
            <i>
              &#10038;
            </i>
          </span>
        </div>

      <Row>
        <Col sm={6}>
            <h4> Qualifications </h4>
              <ul class="timeline">
                <li>
                  <h6>2020</h6>
                  <p>Reflexology </p>
                  <p>Covid-19 Awareness</p>
                  <p>Local Anaesthic Update & Use Of Adrenaline</p>
                </li>
                <li>
                  <h6>2018</h6>
                  <p>First Aid</p>
                  <p>Basic Life Support</p>
                </li>
                <li>
                  <h6>2011 - 2014</h6>
                  <p>PAMOVA</p>
                  <p>Manual Handling</p>
                  <p>Health & Safety</p>
                </li>
                <li>
                  <h6>2006</h6>
                  <p>BSc (Hons) Podiatric Medicine And Surgery</p>
                </li>
              </ul>
        </Col>
        <Col sm={6}>
          <Container className="affiliationContianer">
            <h4> Affiliations </h4>
            <a href="https://www.hcpc-uk.org/" className="affiliations">HCPC registered Member of the College of Podiatry </a>
            <a href="https://www.pppfs.co.uk/" className="affiliations">Member of the private practitioners forum Scotland (PPFS) </a>
            <a href="https://ico.org.uk/" className="affiliations">Member of the ICO</a>
            <a href="https://cop.org.uk/" className="affiliations">Member of the College Of Podiatry</a>
            <a href="http://www.unisonnorthlan.org.uk/" className="affiliations">Committee Member of the Lanarkshire Branch Committee</a>
            <a href="https://www.mygov.scot/pvg-scheme/" className="affiliations">PVG Scheme Member</a>
          </Container>
        </Col>

      </Row>
    </Container>
  </Styles>
)
